import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import PageHeader from '../components/world-of-data/PageHeader'
import Grid from '../components/world-of-data/Grid'
import Layout from '../components/common/Layout'
import CTA from '../components/common/CTA'

const ExplorePage = ({ pageContext }) => {
  const title = pageContext.title
  const pages = pageContext.data
  const isMainPage = title === 'World of Data'
  return (
    <Layout
      title={isMainPage ? 'World of Data' : `Explore ${title} of the world`}
      meta={{
        description:
          'Explore the world of data with Atlas. Discover the most interesting spatial data and maps from around the world.',
        title: isMainPage
          ? 'World of Data | Atlas'
          : `Explore ${title} of the world | Atlas`,
        absoluteImageUrl: 'https://www.atlas.co/images/world-of-data.png',
        type: 'website'
      }}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader title={title} />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <Grid pages={pages} category={isMainPage ? '' : title} />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default ExplorePage
